<template>
    <div class="audio-tmpl">
      <div class="audio-btn" @click="audioSwitch"></div>
      <div class="audio-box" v-show="show">
        <audio :src="url" controls="controls" controlslist="nodownload" preload="none"></audio>
      </div>
    </div>
</template>

<script>
export default {
  name: 'AudioBtn',
  props: ['url'],
  data () {
    return {
      show: false
    }
  },
  methods: {
    audioSwitch () {
      this.show = !this.show
    }
  }
}
</script>

<style lang="scss" scoped>
  .audio-tmpl {
    position: relative;
    .audio-btn {
      background-image: url("../assets/sound.png");
      background-size: 100% 100%;
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }
    .audio-box {
      width: 224px;
      height: 30px;
      position: absolute;
      left: 34px;
      top: -5px;
      audio {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
