<template>
  <div>
    <div :class="['trolley-btn', Object.keys($store.state.examWorkList).length === 0 ? '' : 'trolley-btn-2']"
         @click="spreadChange">
      <div class="trolley-icon-box">
        <img class="trolley-icon" :src="require('@/assets/trolley-icon.png')" alt="">
      </div>
      <div class="trolley-text">考试清单</div>
      <div class="num" v-show="Object.keys($store.state.examWorkList).length > 0">
        {{ Object.keys($store.state.examWorkList).length }}
      </div>
    </div>

    <footer class="footer" v-show="spread">
      <div class="noempty">
        <div class="book">
          <div class="icon"></div>
          <div class="text">
            <div>
              <span>已添加</span><span
              class="num">{{ Object.keys($store.state.examWorkList).length }}</span><span>题</span>
            </div>
            <div class="time">预计38分钟完成</div>
          </div>
          <div class="switch-btn" @click="selectedListToggle">
            <div>
              <div :class="['icon', spread ? 'rotate-180' : '']"></div>
              <div>{{ spread ? '收起' : '展开' }}</div>
            </div>
          </div>
        </div>
        <div class="right" :style="{width : spread ? '100%' : '0'}">
          <div class="submit-btn" :style="{opacity : spread ? '1' : '0'}" @click="workSubmit">全部选好了，去布置
          </div>
        </div>
      </div>
    </footer>

    <div class="bg" v-show="spread" @click="selectedListToggle"></div>
    <div class="modal" v-show="spread">
      <section class="inventory" ref="inventory" :style="{height : inventoryHeight}">
        <header class="header">
          <div>试题清单</div>
          <div class="all-del" @click="allDel">全部清空</div>
        </header>
        <div class="inventory-content">
          <div class="inventory-list" v-for="item in $store.state.examWorkList" :key="item.id">
            <div class="type">{{ item.selectType ? '专用试题' : '通用试题' }}</div>
            <div class="inventory-list-content">
              <div class="info">
                <div class="name">{{ item.name }}</div>
              </div>
              <div class="del" @click="del(item.id)">移除</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'examTrolley',
  data() {
    return {
      spread: false,
      inventoryHeight: 0
    }
  },
  watch: {
    spread: function (val) {
      this.inventoryHeight = val ? 'auto' : 0
    }
  },
  methods: {
    spreadChange() {
      if (Object.keys(this.$store.state.examWorkList).length === 0) return
      this.spread = !this.spread
    },
    selectedListToggle() {
      this.spread = !this.spread
    },
    del(id) {
      const obj = { id, exam: true }
      this.$store.commit('listDel', obj)
      this.$emit('listState')
      if (Object.keys(this.$store.state.examWorkList).length === 0) this.spread = false
    },
    allDel() {
      this.$store.commit('listClear', 'exam')
      this.spread = false
      this.$emit('listState')
    },
    workSubmit() {
      this.$router.push({
        name: 'examWorkSubmit'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  @include flex(flex-end);
  @include font;
  background-color: #fff;
  height: 84px;
  box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
  position: absolute;
  z-index: 100;
  max-width: 1100px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;

  .empty {
    @include flex;
    padding-right: 56px;

    .icon {
      @include icon(url("../assets/work-3.png"), 40px, 50px);
      margin-right: 10px;
    }
  }

  .noempty {
    flex-grow: 1;
    @include flex(flex-end);
    @include font;
    padding: 0 28px;

    .book {
      @include flex;

      .icon {
        @include icon(url("../assets/work-3.png"), 40px, 50px);
        margin-right: 10px;
      }

      .text {
        white-space: nowrap;
      }

      .time {
        @include font(15px, #999);
      }

      .switch-btn {
        @include flex;
        background-color: #F6F6F6;
        width: 44px;
        height: 44px;
        @include font(12px, #666, center);
        margin-left: 20px;
        margin-right: 28px;
        border-radius: 1px;
        cursor: pointer;

        .icon {
          @include icon(url("../assets/spread.png"), 7px, 4px);
          margin: 0 auto 6px;
          transition: transform .3s;
        }
      }
    }

    .right {
      @include flex(flex-end);
      overflow: hidden;
      transition: width .5s;
    }

    .submit-btn {
      background: linear-gradient(to left, #6DBBFC, #309AF2);
      width: 250px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      @include font(17px, #fff, center);
      transition: opacity .3s;
      cursor: pointer;

      &:hover {
        background: #309AF2;
      }
    }
  }

  .num {
    display: inline-block;
    width: 41px;
    text-align: center;
    color: #309AF2;
  }
}

.bg {
  background-color: rgba(0, 0, 0, .3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
}

.modal {
  max-width: 1100px;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.inventory {
  transition: height .3s;
  position: absolute;
  bottom: 84px;
  left: 0;
  right: 0;
  z-index: 99;

  .header {
    @include flex(space-between);
    background-color: #F6F6F6;
    height: 54px;
    padding: 0 56px;
    border-radius: 16px 16px 0 0;
    font-size: 17px;

    .all-del {
      color: #FF3C30;
      cursor: pointer;
    }
  }

  .inventory-content {
    background-color: #fff;
    max-height: calc(100vh - 166px);
    overflow: auto;

    .inventory-list {
      @include flex;
      padding: 0 46px 0 56px;

      &:hover {
        background-color: #F6F6F6;
      }

      .type {
        @include font(15px, #999999);
        margin-right: 34px;
      }

      .inventory-list-content {
        flex-grow: 1;
        @include flex(space-between);
        height: 70px;
        border-bottom: 1px solid #F1F1F1;

        .info {
          @include flex;

          .name {
            @include font;
            margin-right: 20px;
          }

          .tabs {
            li {
              display: inline-block;
              background-color: #EAF4FD;
              height: 26px;
              line-height: 26px;
              padding: 0 10px;
              border-radius: 4px;
              margin-right: 10px;
              @include font(14px, #309AF2, center);
              cursor: pointer;
            }
          }
        }

        .del {
          background-color: #fff;
          width: 67px;
          height: 30px;
          line-height: 30px;
          border-radius: 15px;
          @include font(13px, #FF3C30, center);
          cursor: pointer;

          &:hover {
            background-color: #E5E5E5;
          }
        }
      }
    }
  }
}

.trolley-btn {
  //background-image: url("../../assets/exam-inventory-normal.png");
  //background-size: 100% 100%;
  width: 56px;
  height: 92px;
  background: #309AF2;
  border-radius: 10px;
  position: fixed;
  right: 0;
  bottom: 40px;
  z-index: 1000;
  padding: 8px 8px 12px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: default;
    background: #1485E3;
    .trolley-icon-box {
      background: linear-gradient(360deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 100%);
    }
  }

  .trolley-icon-box {
    width: 40px;
    height: 28px;
    border-radius: 6px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .trolley-icon {
      width: 20px;
      height: 20px;
    }
  }

  .trolley-text {
    width: 28px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    cursor: default;
  }

  .num {
    background-color: #FF3C30;
    width: 20px;
    height: 20px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 10px;
    position: absolute;
    top: -6px;
    right: -6px;
    border: 2px solid #FFFFFF;
  }
}

@media screen and (min-width: 1650px) {
  .trolley-btn {
    right: 17% !important;
  }
}

.trolley-btn-2 {
  //background-image: url("../../../assets/exam-inventory-selected.png");
}
</style>
