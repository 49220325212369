<template>
  <div class="main">
    <div class="alphabet" v-for="item in list" :key="item.id">
      <div class="name">{{item.name}}</div>
      <div class="word">
        <div class="word-list" v-for="word in item.wordList" :key="word.id" @click="audioPlay(word.enPronunciation || word.usaPronunciation)">
          <div class="info">
            <div class="word-name" v-html="word.name"></div>
            <div class="word-soundmark">{{word.enSoundmark || word.usaSoundmark}}</div>
          </div>
          <div class="audio">
            <img src="../../../../assets/sound.png" alt="audio" v-show="audioUrl !== word.enPronunciation && audioUrl !== word.usaPronunciation">
            <img src="../../../../assets/sound.gif" alt="audio" v-show="audioUrl && audioUrl === word.enPronunciation || audioUrl === word.usaPronunciation">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pronunciation',
  props: ['id'],
  data () {
    return {
      list: [],
      audioUrl: ''
    }
  },
  created () {
    this.audio = new Audio()
    this.audio.addEventListener('ended', () => {
      this.audioUrl = null
    })
    this.getWordPronunciationById()
  },
  methods: {
    audioPlay (src) {
      if (!src) {
        this.$alert('无音频资源', '提示', {
          confirmButtonText: '确定'
        })
        return
      }
      if (this.audioUrl === src) {
        this.audioUrl = null
        this.audio.pause()
        return
      }
      this.audio.src = src
      this.audioUrl = src
      this.audio.play()
    },
    getWordPronunciationById () {
      this.$axios({
        method: 'post',
        url: this.$urls.getWordPronunciationById,
        data: {
          id: this.id
        }
      })
        .then((response) => {
          if (response.state === '11') {
            const { alphabetList } = response.data
            for (let i = 0, len = alphabetList.length; i < len; i += 1) {
              const { wordList } = alphabetList[i]
              const { name } = alphabetList[i]
              for (let j = 0, len = wordList.length; j < len; j += 1) {
                const {name: wordName} = wordList[j]
                const index = wordName.indexOf(name)
                if (index !== -1) {
                  const endIndex = index + name.length
                  wordList[j].name = wordName.slice(0, index) + `<span class="col-329cf3">${name}</span>` + wordName.slice(endIndex)
                }
              }
            }
            this.list = alphabetList
          }
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .main {
    background-color: #fff;
    height: calc(100vh - 196px);
    overflow: auto;
    .alphabet {
      padding: 0 0 28px 28px;
      font-size: 17px;
      color: #333;
      .name {
        height: 50px;
        line-height: 50px;
      }
      .word {
        @include flex(flex-start);
        flex-wrap: wrap;
        .word-list {
          @include flex(space-between);
          width: 250px;
          height: 88px;
          padding: 0 20px 0 28px;
          border: 1px solid #E5E5E5;
          border-radius: 8px;
          margin: 0 14px 10px 0;
          cursor: pointer;
          &:hover {
            background-color: #F6F6F6;
          }
          .word-soundmark {
            color: #999999;
            font-size: 16px;
            margin-top: 8px;
          }
          .audio {
            width: 20px;
            height: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
</style>
