<template>
  <article>
    <RouterNav :name="name"></RouterNav>
    <header v-if="!hiddenHeader" class="header">
      <div class="left">
        <div class="line"></div>
        <div class="unit-name" v-html="name"></div>
      </div>
      <xybutton
        :type="selected ? 'del' : 'add'"
        @click="select"
        v-if="[2, 5, 9].includes(flag)"
      >{{selected ? '移除' : '添加'}}</xybutton>
    </header>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
export default {
  name: 'ContentNav',
  components: {
    RouterNav
  },
  props: ['name', 'flag', 'selected', 'hiddenHeader'],
  methods: {
    select () {
      this.$emit('select')
    }
  }
}
</script>

<style lang="scss" scoped>
  .header {
    @include flex(space-between);
    background-color: #fff;
    height: 66px;
    padding-right: 28px;
    margin-bottom: 10px;
    .left {
      @include flex;
      @include font(17px, #333);
      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
    }
  }
</style>
