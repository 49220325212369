<template>
    <div :class="[repeatAfterData ? '' : 'container']" v-loading="loading">
      <div class="top">
        <div class="video">
          <video :src="data.videoUrl" controls controlslist="nodownload" preload="none"></video>
        </div>
        <div class="theme">
          <span class="mr-28">类型：{{data.classify}}</span>
          <span class="mr-28">主题：{{data.theme}}</span>
          <span>难度：{{data.difficult >= 5 ? '高' : data.difficult >= 3 ? '中' : '低'}}</span>
        </div>
        <div class="answer-btn" v-if="repeatAfterData" @click="answerShowChange">
          <div>{{answerShow ? '收起学生录音' : '展开学生录音'}}</div>
          <div :class="['icon', answerShow ? 'rotate-180' : '']"></div>
        </div>
        <div v-if="repeatAfterData">
          <div class="user" v-show="answerShow">
            <ul class="col head">
              <li class="row">姓名</li>
              <li class="row">得分</li>
              <li class="row">答案</li>
            </ul>
            <div v-for="(item, index) in userList" :key="item.id">
              <ul class="col">
                <li class="row">{{item.realName || item.name}}</li>
                <li class="row">{{getScoreValue(item)}}</li>
                <li class="row btn" @click="answerSwitch(index)">
                  <div>展开学生答案</div>
                  <div :class="['icon', item.answerShow ? 'rotate-180' : '']"></div>
                </li>
              </ul>
              <div class="answer" v-show="item.answerShow">
                <div v-for="(answer, idx) in item.answerData" :key="idx">
                  <template v-if="item.answerData">
                    <span class="pre-line" v-html="answer.text"></span>
                    <span>{{answer.score}}</span>
                    <AudioBtn class="user-audio" :url="answer.audioUrl"></AudioBtn>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">原文</div>
        <div class="list" v-for="item in data.list" :key="item.id">
          <div v-html="item.audioTxt"></div>
          <div class="translate" v-html="item.translate" v-show="translateShow"></div>
        </div>
        <div class="tool">
          <div class="btn" @click="translateShowChange">
            <div>{{translateShow ? '收起翻译' : '展开翻译'}}</div>
            <div :class="['icon', translateShow ? 'rotate-180' : '']"></div>
          </div>
        </div>
      </div>
      <div class="content" v-if="data.explain && data.explain.vocabularyList && data.explain.vocabularyList.length > 0">
        <div class="title">考纲词汇</div>
        <div class="list" v-for="(item, index) in data.explain.vocabularyList" :key="index">
          <div class="wordName" v-html="item.wordName"></div>
          <div class="pre-line" v-html="item.translate"></div>
          <div class="translate introduce" v-html="item.introduce" v-show="vocabularyIntroduceShow"></div>
        </div>
        <div class="tool">
          <div class="btn" @click="vocabularyIntroduceShowChange">
            <div>{{vocabularyIntroduceShow ? '收起例句' : '展开例句'}}</div>
            <div :class="['icon', vocabularyIntroduceShow ? 'rotate-180' : '']"></div>
          </div>
        </div>
      </div>
      <div class="content" v-if="data.explain && data.explain.beyondVocabularyList && data.explain.beyondVocabularyList.length > 0">
        <div class="title">超纲词汇</div>
        <div class="list" v-for="(item, index) in data.explain.beyondVocabularyList" :key="index">
          <div class="wordName" v-html="item.wordName"></div>
          <div class="pre-line" v-html="item.translate"></div>
          <div class="translate introduce" v-html="item.introduce" v-show="beyondVocabularyIntroduceShow"></div>
        </div>
        <div class="tool">
          <div class="btn" @click="beyondVocabularyIntroduceShowChange">
            <div>{{beyondVocabularyIntroduceShow ? '收起例句' : '展开例句'}}</div>
            <div :class="['icon', beyondVocabularyIntroduceShow ? 'rotate-180' : '']"></div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import AudioBtn from '@/components/AudioBtn.vue'

export default {
  name: 'Funny',
  components: {
    AudioBtn
  },
  props: ['itemId', 'repeatAfterData'],
  data () {
    return {
      loading: false,
      data: {},
      answerShow: false,
      userList: [],
      translateShow: false,
      vocabularyIntroduceShow: false,
      beyondVocabularyIntroduceShow: false
    }
  },
  created () {
    console.log('this.repeatAfterData', this.repeatAfterData)
    if (this.repeatAfterData) {
      this.data = this.repeatAfterData
      this.userList = this.repeatAfterData.userList
    } else {
      this.id = this.$route.query.id
      this.getRepeatAfterById()
    }
  },
  methods: {
    getScoreValue(item){
      if(item.score != null){
        return item.score
      }
      if(item?.scoreRate != null){
        return item.scoreRate
      }
      return 0
    },
    getRepeatAfterById () {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getRepeatAfterById,
        data: {
          id: this.id
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            this.data = response.data
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    answerShowChange () {
      this.answerShow = !this.answerShow
    },
    answerSwitch (index) {
      const { userList } = this
      const { answerShow } = userList[index]
      const { answerData } = userList[index]
      if (!answerData) {
        this.getAnswerDetail(index)
        return
      }
      userList[index].answerShow = !answerShow
      this.$set(this.userList, index, userList[index])
    },
    getAnswerDetail (index) {
      this.loading = true
      const { userList } = this
      const { id: userId } = userList[index]
      this.$axios({
        method: 'post',
        url: this.$urls.getAnswerByUserIdItemId,
        data: {
          itemId: this.itemId,
          userId
        }
      })
        .then((response) => {
          this.loading = false
          if (response.state === '11') {
            const { data } = response
            const { info } = data
            if (info) {
              const info = JSON.parse(data.info)
              const { sentences } = info
              const arr = []
              for (let i = 0, len = sentences.length; i < len; i += 1) {
                const obj = {}
                const { refText } = sentences[i].ext
                const { details } = sentences[i].ext.result
                const newText = this.common.wordMate(refText, details)
                obj.text = newText
                obj.score = sentences[i].score
                obj.audioUrl = sentences[i].audioUrl
                arr.push(obj)
              }
              userList[index].answerData = arr
              userList[index].answerShow = true
              this.$set(this.userList, index, userList[index])
            } else {
              this.$alert('暂无作答详情', '提示', {
                confirmButtonText: '确定'
              })
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    translateShowChange () {
      this.translateShow = !this.translateShow
    },
    vocabularyIntroduceShowChange () {
      this.vocabularyIntroduceShow = !this.vocabularyIntroduceShow
    },
    beyondVocabularyIntroduceShowChange () {
      this.beyondVocabularyIntroduceShow = !this.beyondVocabularyIntroduceShow
    }
  }
}
</script>

<style scoped lang="scss">
  .container {
    height: calc(100vh - 196px);
    overflow: auto;
    .top {
      background-color: #fff;
      padding: 10px 28px;
      border-bottom: 10px solid #F6F6F6;
      .video {
        height: 211px;
        video {
          width: auto;
          height: 211px;
        }
      }
      .theme {
        margin-top: 10px;
        color: #999999;
        font-size: 14px;
      }
      .mr-28 {
        display: inline-block;
        margin-right: 28px;
      }
    }
    .answer-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 135px;
      height: 32px;
      border: 1px solid #309AF2;
      border-radius: 4px;
      margin: 20px 0 10px;
      color: #309AF2;
      font-size: 14px;
      cursor: pointer;
      .icon {
        @include icon(url("../../../assets/z-1.png"), 7px, 4px);
        margin-left: 4px;
        transition: transform .3s;
      }
    }
    .user {
      background-color: #F6F6F6;
      font-size: 15px;
      .col {
        @include flex;
        height: 50px;
        .row {
          flex: 1 1 33.3%;
          @include flex;
        }
        .btn {
          color: #309AF2;
          font-size: 14px;
          cursor: pointer;
          .icon {
            @include icon(url("../../../assets/z-1.png"), 7px, 4px);
            margin-left: 4px;
            transition: transform .3s;
          }
        }
      }
      .head {
        color: #999999;
      }
      .answer {
        background-color: #F1F1F1;
        padding: 20px 28px;
      }
    }
    .user-audio {
      display: inline-block;
      margin-left: 6px;
      .audio-btn {
        background-image: url("../../../assets/sound-2.png");
      }
    }
    .content {
      padding: 20px 20px 0;
      border-bottom: 10px solid #F6F6F6;
      color: #333333;
      font-size: 17px;
      .title {
        height: 54px;
        line-height: 54px;
        font-weight: bold;
        margin-bottom: 4px;
      }
      .list {
        margin-bottom: 6px;
        .wordName {
          margin: 2px 0 6px;
        }
        .translate {
          background-color: #F6F6F6;
          padding: 7px 14px 9px;
          margin-top: 6px;
          white-space: pre-line;
        }
        .introduce {
          margin-bottom: 20px;
        }
      }
      .tool {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 54px;
        border-top: 1px solid #F1F1F1;
        margin-top: 14px;
        .btn {
          display: flex;
          align-items: center;
          height: 54px;
          color: #309AF2;
          font-size: 14px;
          cursor: pointer;
          .icon {
            background-image: url("../../../assets/z-1.png");
            background-size: 100% 100%;
            width: 7px;
            height: 4px;
            margin-left: 4px;
          }
        }
      }
    }
  }
</style>
